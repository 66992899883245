/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Signatu provides ", React.createElement(_components.a, {
    href: "docs/api/v1/architecture"
  }, "self-service tools"), " to help you build sites that your users\ntrust with their data. Plus, we take care of a lot of the heavy lifting to\nhelp you comply with laws such as the GDPR."), "\n", React.createElement(_components.h2, {
    id: "tools",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tools",
    "aria-label": "tools permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tools"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "docs/api/v1/architecture/trackerdetect"
  }, "Trackerdetect"), " - scan your website to discover and manage 3rd party cookies and scripts."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "docs/api/v1/architecture/consent"
  }, "Consent"), " - manage your user’s consent and/or refusals to data processing activities. Includes tools to manage specifications and texts to present to the users."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "docs/api/v1/architecture/policy"
  }, "Policy"), " - map out the data processing activities in your organization and automatically generate Privacy Policies in multiple languages. Policies can be distributed through our Content Delivery Network (CDN)."), "\n"), "\n", React.createElement(_components.p, null, "Our aim is to make the main bulk of the functionality at Signatu available through\nour API."), "\n", React.createElement(_components.h2, {
    id: "talk-to-us",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#talk-to-us",
    "aria-label": "talk to us permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Talk to us"), "\n", React.createElement(_components.p, null, "If you have suggestions or issues using Signatu please contacts us at\n", React.createElement(_components.a, {
    href: "mailto:support@signatu.com"
  }, "signatu.com"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
